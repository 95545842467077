import { useContext } from "react";
import { Skeleton } from "primereact/skeleton";
import { ContactProfileDetailsContext } from "../macrocomponents/ContactLayout";
import ProfileSVG from "../../assets/profileAvatar.svg";
import { Button } from "primereact/button";

function LeftPanelUserDetails() {
  const { agentProfilePic, agentName, agentEmail, agentPhone, isOpen } = useContext(
    ContactProfileDetailsContext,
  );

  return (
    <>
      <div
        className={`py-3 px-2 border-[.5px]  rounded-lg  border-gray-300 flex  flex-col justify-center items-center ${
          isOpen ? "w-56" : "w-12"
        }`}
      >
        {isOpen && (
          <p className="text-center pb-2 mb-2 font-Inter font-semibold text-xl ">My Advisor</p>
        )}
        <div
          className={`flex gap-1 h-full ${
            isOpen ? "justify-between w-48" : "justify-center"
          } items-center`}
        >
          {agentProfilePic || ProfileSVG ? (
            <div
              className="w-[2.5rem] h-10 rounded-full border-[1px] border-solid border-gray-500 group relative"
              style={{
                backgroundImage: `url(${agentProfilePic || ProfileSVG})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Button
                className="!w-[2.5rem] h-10 rounded-full bg-transparent hover:bg-transparent !border-0 shadow-none"
                tooltip={agentName}
                tooltipOptions={{
                  className: "tooltip-wrapper",
                }}
              />
            </div>
          ) : (
            <Skeleton width="2.5rem" height="2.5rem" shape="circle" className="dark:bg-blue-1100" />
          )}
          {isOpen && (
            <>
              <Button
                icon="pi pi-envelope"
                className="text-iconRoundedBorder hover:!text-iconRoundedBorder !w-[2.5rem] h-10 rounded-full border-[1px] bg-transparent hover:bg-gray-100 border-solid border-iconRoundedBorder flex justify-center items-center hover:cursor-pointer group "
                tooltip={agentEmail}
                tooltipOptions={{
                  className: "tooltip-wrapper",
                }}
              />
              <Button
                icon="pi pi-phone"
                className="text-iconRoundedBorder hover:!text-iconRoundedBorder !w-[2.5rem] h-10 rounded-full border-[1px] bg-transparent hover:bg-gray-100 border-solid border-iconRoundedBorder flex justify-center items-center hover:cursor-pointer group "
                tooltip={agentPhone}
                tooltipOptions={{
                  className: "tooltip-wrapper",
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default LeftPanelUserDetails;
